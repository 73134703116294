import React, { useState, useEffect } from "react";
import { sendNoteCreditOfSupplier } from "../../../apis/apis.logistics/apisProduct";
import { toast } from "react-toastify";
import Select from "react-select";

export function NewNoteCreditForm({
    noteCredit,
    setNoteCredit,
    orders,
    initialNoteCreditState,
}) {
    const filteredOrders = orders
        .filter(
            (o) =>
                o.operationDocumentTypeID === "03" && o.operationStatus === "02"
        )
        .map((o) => ({
            value: o.operationID,
            label: `${o.operationDocumentNumber} | S/ ${Number(
                o.operationAmountTotal
            ).toFixed(2)}`,
        }));
    function handleChange(e) {
        setNoteCredit({ ...noteCredit, [e.target.name]: e.target.value });
    }

    const sendNoteCredit = async (registerData) => {
        try {
            const response = await sendNoteCreditOfSupplier(registerData);
            return response;
        } catch (error) {
            throw error;
        }
    };

    const handleClickBtnSave = async () => {
        if (noteCredit.purchaseID != "0") {
            if (Number(noteCredit.totalCost) >= 0) {
                if (noteCredit.operationDocumentNumber.length > 0) {
                    sendNoteCredit(noteCredit)
                        .then(async (r) => {
                            let data = {
                                operationDocumentNumber:
                                    r.operationDocumentNumber,
                                operationDate: r.operationDate,
                                motive: r.motive,
                                purchaseID: r.purchaseID,
                                totalCost: Number(r.totalCost),
                            };

                            return data;
                        })
                        .then(async (data) => {
                            if (data) {
                                await setNoteCredit(initialNoteCreditState);
                                toast.success(
                                    "Nota de credito registrada con exito!"
                                );
                            } else {
                                toast.error("Ocurrio algun error");
                            }
                        });

                    document
                        .getElementById("btn-close-modal-note-credit")
                        .click();
                    document.getElementById("btn-filter").click();
                } else {
                    toast.error("Verifique el numero de documento");
                }
            } else {
                toast.error("Verifique el monto total");
            }
        } else {
            toast.error("Verifique el doc. de referencia");
        }
    };

    return (
        <div
            className="modal fade"
            id="modal-note-credit"
            aria-modal="true"
            role="dialog"
        >
            <div className="modal-dialog modal-sm">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title font-weight-light">
                            <span className="badge badge-light font-weight-light">
                                Nueva Nota de credito
                            </span>
                        </h4>

                        <button
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            id="btn-close-modal-note-credit"
                        >
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="row">
                            <div className="col">
                                <div className="form-group row mb-2">
                                    <label className="col font-weight-light">
                                        Num Doc
                                    </label>
                                    <div className="col-12">
                                        <input
                                            type="text"
                                            className="form-control font-weight-light"
                                            placeholder="Ingrese ..."
                                            name="operationDocumentNumber"
                                            maxLength={20}
                                            value={
                                                noteCredit.operationDocumentNumber
                                            }
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="form-group row mb-2">
                                    <label className="col font-weight-light">
                                        Motivo:
                                    </label>
                                    <div className="col-12">
                                        <input
                                            type="search"
                                            className="form-control font-weight-light"
                                            placeholder="Ingrese ..."
                                            name="motive"
                                            maxLength={400}
                                            value={noteCredit.motive || ""}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            list="motives"
                                        />
                                        <datalist id="motives">
                                            <option
                                                value={
                                                    "Cambios Vencidos del Mes"
                                                }
                                            />
                                            <option
                                                value={"Limpieza de Zona"}
                                            />
                                            <option
                                                value={"Producto Reventados"}
                                            />
                                            <option
                                                value={"Liquidación General"}
                                            />
                                            <option
                                                value={
                                                    "Reconocimiento por Producto gratis"
                                                }
                                            />
                                            <option
                                                value={"Devoluvion por Ítem"}
                                            />
                                            <option
                                                value={
                                                    "Disminucion en el Valor"
                                                }
                                            />
                                            <option
                                                value={
                                                    "Coreccion por error en la Descripción"
                                                }
                                            />
                                        </datalist>
                                    </div>
                                </div>

                                <div className="form-group row mb-2">
                                    <label className="col font-weight-light">
                                        Fecha:
                                    </label>
                                    <div className="col-12">
                                        <input
                                            type="date"
                                            className="form-control font-weight-light"
                                            name="operationDate"
                                            value={
                                                noteCredit.operationDate || ""
                                            }
                                            onChange={handleChange}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>

                                <div className="form-group row mb-2">
                                    <label className="col font-weight-light">
                                        Doc. Ref.:
                                    </label>
                                    <div className="col-12">
                                        <Select
                                            options={filteredOrders}
                                            className="font-weight-light"
                                            placeholder="Elige"
                                            onChange={handleChange}
                                            value={
                                                filteredOrders.find(
                                                    (option) =>
                                                        option.value ===
                                                        noteCredit.purchaseID
                                                ) || null
                                            }
                                            isClearable // Permite limpiar la selección
                                        />
                                    </div>
                                </div>

                                <div className="form-group row mb-2">
                                    <label className="col font-weight-light">
                                        Total
                                    </label>
                                    <div className="col-12">
                                        <input
                                            type="number"
                                            className="form-control font-weight-light"
                                            placeholder="Ingrese ..."
                                            name="totalCost"
                                            maxLength={6}
                                            value={noteCredit.totalCost}
                                            onChange={handleChange}
                                            autoComplete="off"
                                            onFocus={(e) => e.target.select()}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-default font-weight-light"
                            data-dismiss="modal"
                        >
                            Cerrar
                        </button>
                        <button
                            type="button"
                            className="btn btn-success font-weight-light"
                            onClick={handleClickBtnSave}
                        >
                            Guardar
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
